import React, { Component, Fragment } from 'react';
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText, Container, Row, Label } from 'reactstrap';

class Category extends Component {

  state = {
    trueProfile: {},
    profileTalentList: null
  }

  constructor(props) {
    super(props);

    const { profiles } = props;
    const { states } = JSON.parse(localStorage.getItem('states'));
    const profileTalentList = states.profileTalent;
    const trueProfile = profiles.find((dato) => dato.main === true);

    this.state = {
      trueProfile,
      profileTalentList
    };
  }


  handleSelectChange = async (e) => {
    const { profiles, setFieldValue } = this.props;
    const { trueProfile } = this.state;

    const selectedId = e.target.value;
    console.log("selectedId:", selectedId);

    // Si el array está vacío, crea el objeto con el valor seleccionado
    if (profiles.length === 0) {
      const newProfileTalent = {
        profileUserTalentId: selectedId,
        main: true,
        profileUserTalent: { profile: e.target.options[e.target.selectedIndex].text }
      };

      console.log("newProfileTalent> ", newProfileTalent);
      this.setState({ trueProfile: newProfileTalent });
      setFieldValue("talent.profiles.list", [newProfileTalent]); // Crear el registro con el perfil seleccionado

    } else {
      // Actualizar el perfil principal en el arreglo
      let updated = false;

      const updatedProfileTalent = profiles.map(item => {
        if (!updated && item.id === trueProfile.id) {
          updated = true; // Marcar como actualizado

          // Actualizar el perfil solo si es necesario
          return item.profileUserTalentId !== selectedId
            ? { ...item, profileUserTalentId: selectedId, profileUserTalent: { profile: e.target.options[e.target.selectedIndex].text } }
            : item;
        }

        // Devolver el elemento sin cambios
        return item;
      });

      console.log("---updatedProfileTalent----: ", updatedProfileTalent);

      const newTrueProfile = updatedProfileTalent.find((dato) => dato.main === true);
      console.log("newTrueProfile: ", newTrueProfile);

      this.setState({ trueProfile: newTrueProfile })
      setFieldValue("talent.profiles.list", updatedProfileTalent);
    }
  };

  handleSecondarySelectChange = (e, index) => {
    const { profiles, setFieldValue } = this.props;
    const newSelectedId = e.target.value;

    // Filtramos el perfil principal (main: true) y los perfiles secundarios (main: false)
    const mainProfile = profiles.find(profile => profile.main === true);
    const secondaryProfiles = profiles.filter(profile => profile.main === false);

    // Actualizamos solo el perfil secundario en la posición específica
    const updatedSecondaryProfiles = secondaryProfiles.map((profile, i) => {
      if (i === index) {
        return {
          ...profile,
          profileUserTalentId: newSelectedId,
          profileUserTalent: { profile: e.target.options[e.target.selectedIndex].text }
        };
      }
      return profile;
    });

    // Combinamos el perfil principal intacto con los perfiles secundarios actualizados
    const updatedProfiles = [mainProfile, ...updatedSecondaryProfiles];

    // Actualizamos solo los perfiles secundarios en el campo 'talent.profiles.list'
    setFieldValue("talent.profiles.list", updatedProfiles);
  };

  handleRemoveProfile = (index) => {
    const { profiles, setFieldValue } = this.props;

    // Filtramos el perfil principal (main: true) y los perfiles secundarios (main: false)
    const mainProfile = profiles.find(profile => profile.main === true);
    const secondaryProfiles = profiles.filter(profile => profile.main === false);

    // Eliminamos el perfil en la posición especificada
    const updatedSecondaryProfiles = secondaryProfiles.filter((_, i) => i !== index);

    // Combinamos el perfil principal intacto con los perfiles secundarios actualizados
    const updatedProfiles = [mainProfile, ...updatedSecondaryProfiles];

    // Actualizamos 'talent.profiles.list' sin el perfil eliminado
    setFieldValue("talent.profiles.list", updatedProfiles);
  };

  handleAddProfile = () => {
    const { profiles, setFieldValue } = this.props;

    // Verifica el límite de 9 perfiles secundarios
    const secondaryProfiles = profiles.filter(profile => profile.main === false);
    if (secondaryProfiles.length >= 9) {
      alert("No puedes agregar más de 9 perfiles secundarios.");
      return;
    }

    // Crea un perfil nuevo en blanco
    const newProfile = {
      id: null,  // ID temporal único para el nuevo perfil
      main: false,
      profileUserTalentId: null,  // Valor inicial en blanco
      profileUserTalent: { profile: "Selecciona un perfil" }
    };

    // Agrega el nuevo perfil a la lista
    const updatedProfiles = [...profiles, newProfile];
    setFieldValue("talent.profiles.list", updatedProfiles);
  };

  renderSecondaryProfiles = () => {
    const { profiles } = this.props;
    const secondaryProfiles = profiles.filter(profile => profile.main === false);

    return secondaryProfiles.map((profile, index) => (
      <InputGroup key={index} className="mt-2">
        <Input
          type="select"
          value={profile.profileUserTalentId}
          onChange={(e) => this.handleSecondarySelectChange(e, index)}
        >
          <option value="">{profile.profileUserTalent.profile}</option>
          {this.state.profileTalentList.map((stateList, i) => (
            stateList.id !== profile.profileUserTalentId && (
              <option key={i} value={stateList.id}>
                {stateList.profile}
              </option>
            )
          ))}
        </Input>
        <InputGroupAddon addonType="append">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => this.handleRemoveProfile(index)}
          >
            <i className="fa fa-minus"></i>
          </button>
        </InputGroupAddon>
      </InputGroup>
    ));
  };

  render() {
    const { trueProfile, profileTalentList } = this.state;
    const { handleBlur } = this.props;

    if (!profileTalentList) {
      return null;
    }

    console.log("trueProfile: ", trueProfile);
    console.log("profileTalentList: ", profileTalentList);


    return (
      <Fragment>
        <Row>
          {profileTalentList &&
            <Col sm={12} md={12} className="mt-2">
              <InputGroup>
                <Label>Categoria principal</Label>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-gavel"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  name="state"
                  placeholder="Estado"
                  value={trueProfile?.profileUserTalentId || ""}
                  onBlur={handleBlur}
                  onChange={this.handleSelectChange}
                >
                  <option value={trueProfile?.profileUserTalentId || ""}>
                    {trueProfile?.profileUserTalent?.profile || "Selecciona un estado"}
                  </option>
                  {profileTalentList.map((stateList, index) => {
                    if (stateList.id !== trueProfile?.profileUserTalentId) {
                      return (
                        <option key={index} value={stateList.id}>
                          {stateList.profile}
                        </option>
                      );
                    }
                    return null; // No mostrar la opción que coincide
                  })}
                </Input>

              </InputGroup>
            </Col>
          }
        </Row>

        <Row>
          <Col sm={12} md={12} className="mt-2">
            <Label>
              Perfiles secundarios
              <button
                type="button"
                className="btn btn-success ml-2"
                onClick={this.handleAddProfile}
              >
                <i className="fa fa-plus"></i>
              </button>
            </Label>
            {this.renderSecondaryProfiles()}
          </Col>
        </Row>
      </Fragment>
    )
  }
}

export default Category;
