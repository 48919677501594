import React, { Component, Fragment } from 'react';
import { withApollo, compose } from 'react-apollo';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import LoginView from './LoginView'
import { getUserLogin, getStatesAgencyUser, getAdminLogin } from '../../../controllers/graphql/queries';
import queryString from 'query-string';
import './login.css'
import ServiceInteractor from '../../../controllers/services/ServiceInteractor';
const Interactor = new ServiceInteractor();

class Login extends Component {
  state = {
    error: undefined,
    messageState: '',
    statePass: false,
    states: {
      agency: [],
      user: []
    },
    typeParam: false
  }

  componentWillMount() {
    const queryParams = queryString.parse(this.props.location.search);
    const typeParam = queryParams.select || false;
    console.log("typeParam: ", typeParam);
    this.setState({ typeParam });
  }

  componentDidMount() {
    this.getStates()
  }

  getStates = async () => {
    if (!localStorage.getItem('states')) {
      try {
        const { client } = this.props;
        const { data } = await client.query({ query: getStatesAgencyUser })

        localStorage.setItem('states', JSON.stringify({
          states: {
            agency: data.statesAgency.list,
            user: data.statesUser.list
          }
        }))

        this.setState({
          states: update(this.state.states, {
            agency: { $set: data.statesAgency.list },
            user: { $set: data.statesUser.list }
          }),
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      const { states } = JSON.parse(localStorage.getItem('states'));
      this.setState({
        states: update(this.state.states, {
          agency: { $set: states.agency },
          user: { $set: states.user }
        }),
      })
    }
  }

  login = (values) => {
    const { client, history } = this.props;
    const { user, password } = values;
    const { typeParam } = this.state;

    return new Promise(async (resolve, reject) => {
      try {
        let response = null;

        if (typeParam == 'admin') {
          console.log("es admin");
          const { data } = await client.query({
            query: getAdminLogin,
            variables: {
              email: user,
              password: password
            }
          });
          response = data;
        } else {
          const { data } = await client.query({
            query: getUserLogin,
            variables: {
              email: user,
              password: password
            }
          });
          response = data;
        }

        const users = response.users || response.userAdmins;

        if (users.list.length === 0) {
          this.changeStateError('Usuario o contraseña invalido')
        } else {

          if (users.list[0].state === 71) {
            this.changeStateError('Por favor revise su correo o comuniquese a soporte Niriun')
          } else if (users.list[0].state >= 72 || users.list[0].state <= 74) {
            let bearerTokenMovil = '';

            if (typeParam == 'admin') {
              const data = {
                email: user,
                password
              }
              bearerTokenMovil = await Interactor.autenticationBackendMovil(data);
            }
            
            localStorage.setItem('account', JSON.stringify({
              id: users.list[0].id,
              nameUser: users.list[0].name,
              lastnameUser: users.list[0].lastname,
              stateUser: users.list[0].state,
              agency: users.list[0].agencies?.list[0].id,
              nameAgency: users.list[0].agencies?.list[0].name ,
              logoAgency: users.list[0].agencies?.list[0].logo,
              phone: users.list[0].phone,
              email: user,
              legalNature: users.list[0].agencies?.list[0].legalNature.includes('NATURAL') ? 'persona' : 'comercio',
              roleId: users.list[0].roleId,
              payment: users.list[0].agencies?.list[0].payment,
              bearerTokenMovil: bearerTokenMovil?.user?.token || '',
            }))

            history.push('/dashboard')

          } else if (users.list[0].state === 75) {
            this.changeStateError('Este usuario esta suspendido por favor comuniquese a soporte Niriun')
          } else {
            this.changeStateError('Tenemos problemas en nuestro sistema por favor intenta mas tarde')
          }
        }

        resolve(response)
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }

  showPass = () => this.setState({ statePass: !this.state.statePass })

  changeStateMessage = () => this.setState({ error: '', messageState: '' })

  changeStateError = messageError => this.setState({ error: messageError })

  render() {
    return (
      <Fragment>
        <LoginView
          {...this.state}
          login={this.login}
          showPass={this.showPass}
          changeStateMessage={this.changeStateMessage}
          changeStateError={this.changeStateError}
        />
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withRouter
)(Login);
