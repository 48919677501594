const ServiceFactory = {
    routeAPI: '/api',
    routeGraphQL: '/graphql',
    routeImages: '/static/images',
    routes: {
        notify: '/notify',
        notifyRegister: '/api/register-fast',
        notifyForgotPassword: '/api/forgot-password',
        notifyActiveAccount: '/api/active-account',
        notifyUpdateDataProducer: '/notify/update-data-producer',
        notifyClient: '/notify/client',
        notifyClientPersonalized: '/api/client-personalized',
        notifyClientGeneric: '/notify/client-generic',
        notifyApplyAgentSchedule: '/api/apply-agent-schedule',
        notifyQuotation: '/api/send-quatation',
        upload: '/upload-images',
        pushNotification: '/api/notification',
        saveContract: '/api/save-contract',
        postProfile: '/post-profile'
    }
}

ServiceFactory.serverURL = process.env.REACT_APP_HOMEPAGE;
ServiceFactory.serverImages = `${process.env.REACT_APP_API_ENDPOINT}${ServiceFactory.routeImages}`;
ServiceFactory.serverGraphQL = `${process.env.REACT_APP_API_ENDPOINT}/graphql`;
ServiceFactory.serverAPI = `${process.env.REACT_APP_API_ENDPOINT}${ServiceFactory.routeAPI}`;
ServiceFactory.serverFirebaseMessages = `https://fcm.googleapis.com/fcm/send`;
ServiceFactory.keyFCM = `key=AAAAbzICxvU:APA91bHW40E5BlXfQld6hz5ELrXDJcaSHswz5WgIokcNItL21xFYGGzd4ixdfxvNu_IqMwQK2eigtBZiX_mZqkTz58SSwL72njkpKZ8p3AS9pDZHKIeRmZoTsJlTyHQnQd_YLz5w29ni`
console.log(ServiceFactory.serverURL);
export { ServiceFactory }
